import React from "react";
import PropTypes from "prop-types";
 
const Spinner = ({ size = "md", className = "" }) => {
  const sizeStyles = {
    sm: "w-12 h-12",
    md: "w-20 h-20",
    lg: "w-28 h-28",
    xl: "w-40 h-40",
  };
 
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`
          relative
          ${sizeStyles[size] || sizeStyles.md}
          ${className}
          animate-spin-right
        `}
      >
        {/* Outer rotating rings */}
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index}
            className="absolute border-4 border-gray-400 rounded-full opacity-70"
            style={{
              width: "25%",
              height: "25%",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%) rotate(${index * 60}deg) translateY(-140%)`,
            }}
          />
        ))}
 
        {/* Inner glowing dots */}
        <div className="absolute flex items-center justify-center w-full h-full animate-spin-left">
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="absolute rounded-full bg-blue-500 shadow-lg"
              style={{
                width: "14%",
                height: "14%",
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%) rotate(${index * 60}deg) translateY(-70%)`,
              }}
            />
          ))}
        </div>
      </div>
      <p className="mt-3 text-sm text-gray-600 animate-fade-in">Please Wait...</p>
    </div>
  );
};
 
Spinner.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  className: PropTypes.string,
};
 
// Custom animations for smoothness and appeal
const styles = `
  @keyframes spin-right {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .animate-spin-right {
    animation: spin-right 4s linear infinite;
  }
  @keyframes spin-left {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }
  .animate-spin-left {
    animation: spin-left 4s linear infinite;
  }
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  .animate-fade-in {
    animation: fade-in 1s ease-in-out;
  }
`;
 
export default Spinner;
 
 