

import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Spinner from '../src/components/Spinner/Spinner'; 

// Admin Pages
const Overview = lazy(() => import('./pages/Admin/Overview'));
const AddEmployee = lazy(() => import('./pages/Admin/AddEmployee'));
const Verification = lazy(() => import('./pages/Admin/Verification'));
const VerificationEdit = lazy(() => import('./pages/Admin/VerificationEdit'));
const SignIn = lazy(() => import('./components/Auth/Admin/SignIn'));
const SignUp = lazy(() => import('./components/Auth/Admin/SignUp'));
const ImportEmployee = lazy(() => import('./pages/Admin/ImportEmployee'));
const Notification = lazy(() => import('./pages/Admin/Notification'));

// Employee Pages
const EmployeeSignIn = lazy(() => import('./components/Auth/Employee/EmployeeSignIn'));
const EmployeePasswordReset = lazy(() => import('./components/Auth/Employee/EmployeePasswordReset'));
const EmployeeDocument = lazy(() => import('./pages/Employee/EmployeeDocument'));
const EmployeeIdentity = lazy(() => import('./pages/Employee/EmployeeIdentity'));
const EmployeeLiveCheck = lazy(() => import('./pages/Employee/EmployeeLiveCheck'));
const EmployeeVerificationStatus = lazy(() => import('./pages/Employee/EmployeeVerificationStatus'));

// Private Route Placeholder
const PrivateRoute = ({ children }) => {
  const isAuthenticated = true; // Replace with actual auth logic
  return isAuthenticated ? children : <Navigate to="/Admin/SignIn" replace />;
};

const MainContent = () => {
  const location = useLocation();
  const [mainClass, setMainClass] = useState("p-8 mt-16");

  useEffect(() => {
    if (location.pathname === "/Employee/VerificationStatus") {
      setMainClass("");
    } else {
      setMainClass("p-8 mt-16");
    }
  }, [location]);

  return (
    <main className={mainClass}>
      <Routes>
        {/* Redirect Root */}
        <Route path="/" element={<Navigate to="/Admin/SignIn" replace />} />

        {/* Admin Routes */}
        <Route path="/Admin">
          <Route path="SignIn" element={<SignIn />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route
            path="Overview"
            element={
              <PrivateRoute>
                <Overview />
              </PrivateRoute>
            }
          />
          <Route
            path="AddEmployee"
            element={
              <PrivateRoute>
                <AddEmployee />
              </PrivateRoute>
            }
          />
          <Route
            path="Verification"
            element={
              <PrivateRoute>
                <Verification />
              </PrivateRoute>
            }
          />
          <Route
            path="VerificationEdit"
            element={
              <PrivateRoute>
                <VerificationEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="ImportEmployee"
            element={
              <PrivateRoute>
                <ImportEmployee />
              </PrivateRoute>
            }
          />
          <Route
            path="Notification"
            element={
              <PrivateRoute>
                <Notification />
              </PrivateRoute>
            }
          />
        </Route>

        {/* Employee Routes */}
        <Route path="/Employee">
          <Route path="SignIn" element={<EmployeeSignIn />} />
          <Route path="EmployeePasswordReset" element={<EmployeePasswordReset />} />
          <Route
            path="Document"
            element={
              <PrivateRoute>
                <EmployeeDocument />
              </PrivateRoute>
            }
          />
          <Route
            path="Identity"
            element={
              <PrivateRoute>
                <EmployeeIdentity />
              </PrivateRoute>
            }
          />
          <Route
            path="LiveCheck"
            element={
              <PrivateRoute>
                <EmployeeLiveCheck />
              </PrivateRoute>
            }
          />
          <Route
            path="VerificationStatus"
            element={
              <PrivateRoute>
                <EmployeeVerificationStatus />
              </PrivateRoute>
            }
          />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/Employee/SignIn" replace />} />
      </Routes>
    </main>
  );
};

export default function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50 relative">
        <Suspense
          fallback={
            <div className="fixed inset-0 flex items-center justify-center bg-gray-50 z-50">
              <Spinner size="lg" />
            </div>
          }
        >
          <MainContent />
        </Suspense>
      </div>
    </Router>
  );
}